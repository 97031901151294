import * as _utils2 from "@near-js/utils";
var _utils = _utils2;
try {
  if ("default" in _utils2) _utils = _utils2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Logger = void 0;
var utils_1 = _utils;
Object.defineProperty(exports, "Logger", {
  enumerable: true,
  get: function () {
    return utils_1.Logger;
  }
});
export default exports;